import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';

import DatePicker from 'react-datepicker';
import Link from '../Link/Link';
import OrbReportDials from '../OrbReportDials/OrbReportDials';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import ExpandableItem from '../ExpandableItem/ExpandableItem';
import PopoutPanelIconHeading, { types } from '../PopoutPanelIconHeading/PopoutPanelIconHeading';
import withLocalizedContent from '../../language/withLocalizedContent';
import OrbSchoolReportLoader from '../SkeletonLoader/Orb/OrbSchoolReportLoader';
import styles from './SchoolReport.scss';
import { getOrbClassReportLink } from '../../utils/orb';
import { featureIsEnabled } from '../../globals/envSettings';
import PillTabs from '../PillTabs/PillTabs';
import { storeSavedSettings } from '../../redux/reducers/savedSettings';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';
import NotificationBoxText from './NotificationBoxText.js';
import { startGenerateReportRequest } from '../../redux/reducers/schoolReport.reducer.js';

class SchoolReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stateClassrooms: {},
      showDatePicker: false,
      minDate: null,
      maxDate: new Date(),
      displayInfoPanel: false,
      reportType: 'orbclassreport'
    };
  }

  componentDidMount() {
    const { schoolDials, orgId } = this.props;
    if (
      (featureIsEnabled('mat-report-change-for-reducers') && !schoolDials.data[orgId]) ||
      !featureIsEnabled('mat-report-change-for-reducers')
    ) {
      this.initSchoolDial();
    }
  }

  componentDidUpdate(prevProps) {
    const { locationId } = this.props;
    if (locationId !== prevProps.locationId) {
      this.initSchoolDial();
    }
  }

  toggleAllReports = () => {
    const { classrooms, getDials, dials } = this.props;

    const expand = !this.allReportExpanded();
    const classroomIds = classrooms.map(({ id }) => id);

    if (expand) {
      classroomIds.forEach(classroomId => {
        if (!dials[classroomId]) {
          getDials(classroomId);
        }
      });
    }

    const stateClassrooms = classroomIds.reduce((prev, classroomId) => {
      prev[classroomId] = expand;
      return prev;
    }, {});

    this.setState({ stateClassrooms });
  };

  toggleReport = classroomId => () => {
    const { stateClassrooms } = this.state;
    stateClassrooms[classroomId] = !stateClassrooms[classroomId];
    this.setState({
      stateClassrooms
    });

    const { dials, getDials } = this.props;
    if (!dials[classroomId]) {
      getDials(classroomId);
    }
  };

  exportReport = () => {
    const {
      startDate,
      endDate,
      orgId,
      startGenerateReportRequestAction,
      schoolDials,
      noProductFoundError
    } = this.props;
    console.log(noProductFoundError);
    const isoStartDateWithoutTimezone = moment(startDate).format('YYYY-MM-DD');
    const isoEndDateWithoutTimezone = moment(endDate).format('YYYY-MM-DD');
    const levels = schoolDials.data[orgId] ? schoolDials.data[orgId].levels : {};
    const options = {
      options: { orgId, startDate: isoStartDateWithoutTimezone, endDate: isoEndDateWithoutTimezone, levels }
    };

    const { reportType } = this.state;
    if (!noProductFoundError) {
      startGenerateReportRequestAction(reportType, options);
    }

    this.setState({ displayInfoPanel: true });
  };

  showDatePicker = () => {
    const { startDate, endDate, dateIsSelected } = this.props;
    const { showDatePicker, minDate, maxDate } = this.state;

    const selector = (
      <button type="button" onClick={() => this.handleOpenDatePicker()} className={styles.datePickerCalendarIcon}>
        <SVGIcon
          className={styles.itemDocumentIcon}
          glyph={GLYPHS.ICON_CALENDAR_DATEPICKER}
          width="17.5"
          height="17.5"
          glyphViewBox="0 0 17.5 17.5"
        />
      </button>
    );
    const exportButton = (
      <button
        type="button"
        onClick={e => {
          e.preventDefault();
          this.exportReport();
        }}
        className={styles.exportButton}
        disabled={!dateIsSelected}
      >
        <SVGIcon
          className={styles.itemDocumentIcon}
          glyph={GLYPHS.ICON_EXPORT}
          width="17.5"
          height="17.5"
          glyphViewBox="0 0 17.5 17.5"
        />
        <div className={styles.itemDownload}>Export</div>
      </button>
    );

    const datePickerWrapper = (
      <div className={styles.datePickerWrapper}>
        {selector}
        {exportButton}
      </div>
    );

    const datePicker = (
      <DatePicker
        onChange={this.handleChangeDatePicker}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        inline
        onClickOutside={this.handleOpenDatePicker}
        calendarClassName={styles.reactDatePickerAbsolute}
        minDate={minDate}
        maxDate={maxDate}
      />
    );

    return [datePickerWrapper, showDatePicker ? datePicker : null];
  };

  handleOpenDatePicker = () => {
    const { showDatePicker } = this.state;
    this.setState({ showDatePicker: !showDatePicker });
  };

  handleChangeDatePicker = dates => {
    const { storeSavedSettingsAction } = this.props;
    const [startDate, endDate] = dates;

    storeSavedSettingsAction({ schoolReport: { startDate, endDate } });
    if (endDate) {
      storeSavedSettingsAction({ schoolReport: { dateIsSelected: true } });

      this.handleOpenDatePicker();
    }
    if (startDate && !endDate) {
      storeSavedSettingsAction({ schoolReport: { dateIsSelected: false } });

      const dateDaysInterval = 365;
      const minDate = new Date(startDate).setDate(new Date(startDate).getDate() - dateDaysInterval);
      const maxDate = new Date(startDate).setDate(new Date(startDate).getDate() + dateDaysInterval);

      this.setState({ minDate, maxDate: maxDate > new Date() ? new Date() : maxDate });
    }
  };

  closeNotification = () => {
    this.setState({ displayInfoPanel: false });
  };

  initSchoolDial() {
    const { locationId, getSchoolDials, schoolDials, orgId } = this.props;
    if (locationId && !schoolDials.data[orgId]) {
      getSchoolDials(locationId);
    }
  }

  allReportExpanded() {
    const { stateClassrooms } = this.state;
    const { classrooms } = this.props;
    return classrooms?.map(({ id }) => id).every(classroomId => stateClassrooms[classroomId]);
  }

  renderEngagementTab() {
    const { dateIsSelected, startDate, endDate, errorMessage, userEmail, noProductFoundError } = this.props;
    const { displayInfoPanel } = this.state;

    let currentDateSelected = '';
    const startDateFormat = moment(startDate).format('Do MMMM, YYYY');
    const endDateFormat = moment(endDate).format('Do MMMM, YYYY');
    if (startDate) {
      currentDateSelected += `${startDateFormat} - `;
    }

    if (endDate) {
      currentDateSelected += endDateFormat;
    }
    const dateLabel = dateIsSelected ? currentDateSelected : 'Do MMMM, YYYY - Do MMMM, YYYY';
    return (
      <div className={styles.schoolReportTabsContent}>
        <div>
          <p>
            Clicking export sends an overall engagement report <b>via email</b> containing data from all of the classes
            in the school. You can use this report to view how students across the whole school have engaged with Oxford
            Reading Buddy over the selected reporting period.
          </p>
          <div className={styles.schoolReportActionButtons}>
            <span>Reporting range:</span> <strong>{dateLabel} </strong> {this.showDatePicker()}
          </div>
        </div>

        {displayInfoPanel && errorMessage === null && !noProductFoundError && (
          <NotificationBoxText
            handleClickClosePanel={this.closeNotification}
            message={`The engagement report has been sent to the following e-mail address:<strong> ${userEmail} </strong>`}
            customClassName="NotificationBoxText_schoolReport"
          />
        )}
        {displayInfoPanel && errorMessage !== null && (
          <NotificationBoxText
            handleClickClosePanel={this.closeNotification}
            title={errorMessage}
            message="The engagement report has not been sent to the e-mail address"
            customClassName="NotificationBoxText_schoolReport"
          />
        )}
        {displayInfoPanel && noProductFoundError && noProductFoundError.code === 9023 && (
          <NotificationBoxText
            handleClickClosePanel={this.closeNotification}
            title="There are no products in the organization."
            message="The engagement report has not been sent to the e-mail address"
            customClassName="NotificationBoxText_schoolReport"
          />
        )}
      </div>
    );
  }

  renderDialsTab() {
    const { schoolDials, locationId, orgId } = this.props;
    return featureIsEnabled('mat-report-change-for-reducers') ? (
      <OrbReportDials
        dialsData={
          schoolDials.submitting && schoolDials && schoolDials.dials && !schoolDials.dials[orgId]
            ? null
            : schoolDials.data[orgId]
        }
        locationId={locationId}
      />
    ) : (
      <OrbReportDials dialsData={schoolDials.submitting ? null : schoolDials} locationId={locationId} />
    );
  }

  render() {
    const { stateClassrooms } = this.state;
    const {
      className = null,
      schoolDials,
      classrooms,
      dials,
      locationId,
      orgId,
      localizedContent: { orbClassReportPage },
      failure,
      orbClassReportTab,
      storeSavedSettingsAction
    } = this.props;

    if (failure || schoolDials.error || !classrooms || !classrooms?.length) {
      return (
        <PopoutPanelIconHeading
          type={types[types.WARNING]}
          title={orbClassReportPage.school_report_error_title}
          subtitle={orbClassReportPage.school_report_error_subtitle}
        />
      );
    }
    if (schoolDials.submitting && schoolDials && schoolDials.dials && !schoolDials.dials[orgId]) {
      return <OrbSchoolReportLoader />;
    }

    const tabItems = featureIsEnabled('orb-school-report-export')
      ? [
          {
            action: () => storeSavedSettingsAction({ orbClassReportTab: 0 }),
            tabText: 'Progress/Attainment',
            panelContent: this.renderDialsTab()
          },
          {
            action: () => storeSavedSettingsAction({ orbClassReportTab: 1 }),
            tabText: 'Engagement',
            panelContent: this.renderEngagementTab()
          }
        ]
      : null;

    return (
      <div className={className}>
        <div className={styles.schoolReportDialContainer}>
          <div className={styles.title}>School report</div>
          {featureIsEnabled('orb-school-report-export') ? (
            <PillTabs current={orbClassReportTab} items={tabItems} />
          ) : (
            this.renderDialsTab()
          )}
        </div>
        {classrooms && classrooms.length > 0 ? (
          <div className={styles.toggleSwitchContainer}>
            <ToggleSwitch
              id="schoolReportShowAllClass"
              label="Show all class reports"
              value={this.allReportExpanded()}
              onChange={() => this.toggleAllReports()}
            />
          </div>
        ) : null}
        {classrooms?.map(({ id, name }) => (
          <ExpandableItem
            key={id}
            expanded={stateClassrooms[id]}
            onToggleExpand={this.toggleReport(id)}
            label={`${name} report`}
          >
            <OrbReportDials dialsData={dials[id]} locationId={locationId} hideDescription enableTextDial />
            {dials[id] && (
              <Link className={styles.viewMoreLink} to={getOrbClassReportLink(orgId, id)}>
                View more
              </Link>
            )}
          </ExpandableItem>
        ))}
      </div>
    );
  }
}

SchoolReport.propTypes = {
  className: PropTypes.string,
  schoolDials: PropTypes.object,
  classrooms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ),
  dials: PropTypes.objectOf(
    PropTypes.shape({
      attainment: PropTypes.number,
      progress: PropTypes.number,
      engagement: PropTypes.number
    })
  ),
  locationId: PropTypes.string,
  getDials: PropTypes.func,
  getSchoolDials: PropTypes.func,
  orgId: PropTypes.string,
  failure: PropTypes.bool,
  localizedContent: PropTypes.object,
  orbClassReportTab: PropTypes.number,
  storeSavedSettingsAction: PropTypes.func,
  dateIsSelected: PropTypes.bool,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  startGenerateReportRequestAction: PropTypes.func,
  errorMessage: PropTypes.string,
  userEmail: PropTypes.string,
  noProductFoundError: PropTypes.object
};

export default compose(
  withLocalizedContent('orbClassReportPage'),
  connect(
    state => {
      const schoolReportSettings = state.savedSettings.settings.schoolReport;

      return {
        orbClassReportTab: state.savedSettings.settings.orbClassReportTab,
        dateIsSelected: schoolReportSettings.dateIsSelected,
        startDate: schoolReportSettings.startDate,
        endDate: schoolReportSettings.endDate,
        errorMessage: state.schoolReport.errorMessage,
        userEmail: state.identity.email
      };
    },
    dispatch => ({
      storeSavedSettingsAction: rangeValue => {
        dispatch(storeSavedSettings(rangeValue));
      },
      startGenerateReportRequestAction: (reportType, options) => {
        dispatch(startGenerateReportRequest(reportType, options));
      }
    })
  )
)(SchoolReport);
