import * as t from '../../actionTypes';
import appSettings from '../../../globals/appSettings';

import {
  REDEEM_CODE_REGEX,
  REDEEM_VST_CODE_REGEX,
  INCLUDES_LETTERS_REDEEM_CODE_REGEX,
  INCLUDES_LETTERS_VST_REDEEM_CODE_REGEX,
  isAdminRole,
  getRoleBasedOnValue,
  validateBothTypeOfCodes
} from './activationCodeUtil';

export const formStates = {
  INPUTTING: 'INPUTTING',
  CHECKING: 'CHECKING',
  SUBMITTED: 'SUBMITTED'
};
export const formStatesRedeemCodeGuest = {
  INPUTTING: 'INPUTTING',
  SUBMITTING: 'SUBMITTING',
  SUCCESSFUL: 'SUCCESSFUL',
  FAILED: 'FAILED'
};

export const vstRedeemCodeRole = {
  ADMIN: {
    name: 'ADMIN',
    letter: 'A'
  },
  TEACHER: {
    name: 'TEACHER',
    letter: 'T'
  },
  STUDENT: {
    name: 'STUDENT',
    letter: 'S'
  }
};

export const initialState = {
  formState: formStates.INPUTTING,
  redeemCodeValue: '',
  redeemCodeRole: '',
  isVstCode: false,
  redeemCodeValueValidationMessage: null,
  redeemCodeValueIsValid: false,
  redeemCodeValueIsError: false,
  errorOccurred: false,
  errorMessage: null,
  success: false,
  guestEmailId: '',
  guestEmailIdValueIsError: false,
  guestEmailIdValueIsValid: false,
  formStateGuest: formStates.INPUTTING
};

export default function activationCode(state = initialState, action) {
  switch (action.type) {
    case t.CHECK_ACTIVATION_CODE_REQUEST:
      return {
        ...state,
        formState: formStates.CHECKING
      };
    case t.CHECK_ACTIVATION_CODE_REQUEST_SUCCESS:
      return {
        ...state,
        success: true,
        errorOccurred: false,
        redeemCodeValueValidationMessage: action.data.licenceStatus,
        formState: formStates.SUBMITTED
      };
    case t.CHECK_ACTIVATION_CODE_REQUEST_FAILURE:
      return {
        ...state,
        errorOccurred: true,
        redeemCodeValueValidationMessage: action.data.licenceStatus,
        errorMessage: action.data.errorMessage,
        success: false
      };
    case t.SET_VST_ACTIVATION_CODE:
      return {
        ...state,
        redeemCodeValue: REDEEM_VST_CODE_REGEX.test(action.activationCode)
          ? action.activationCode.replace(/([ASTast]{1})(\d{3})(\d{3})(\d{4})/, '$1-$2-$3-$4')
          : action.activationCode.replace(/(\d{4})(\d{4})(\d{4})/, '$1-$2-$3'),
        redeemCodeValueIsValid:
          (REDEEM_VST_CODE_REGEX.test(action.activationCode) || REDEEM_CODE_REGEX.test(action.activationCode)) &&
          !isAdminRole(action.activationCode),
        redeemCodeValueIsError:
          !INCLUDES_LETTERS_VST_REDEEM_CODE_REGEX.test(action.activationCode) ||
          !INCLUDES_LETTERS_REDEEM_CODE_REGEX.test(action.activationCode.slice(1)) ||
          isAdminRole(action.activationCode),
        isVstCode: !!getRoleBasedOnValue(action.activationCode),
        redeemCodeRole: getRoleBasedOnValue(action.activationCode),
        errorMessage: null
      };
    case t.SET_ACTIVATION_CODE:
      return {
        ...state,
        redeemCodeValue: action.activationCode.replace(/(\d{4})(\d{4})(\d{4})/, '$1-$2-$3'),
        redeemCodeValueIsValid: REDEEM_CODE_REGEX.test(action.activationCode),
        redeemCodeValueIsError: !INCLUDES_LETTERS_REDEEM_CODE_REGEX.test(action.activationCode),
        isVstCode: false,
        errorMessage: null
      };
    case t.SET_GUEST_EMAIL_ID:
      return {
        ...state,
        guestEmailId: action.emailId,
        guestEmailIdValueIsError: false,
        guestEmailIdValueIsValid: appSettings.EMAIL_REGEX.test(action.emailId),
        errorMessage: null
      };
    case t.VALIDATE_GUEST_EMAIL_ID:
      return {
        ...state,
        guestEmailIdValueIsValid: appSettings.EMAIL_REGEX.test(state.guestEmailId),
        guestEmailIdValueIsError: !appSettings.EMAIL_REGEX.test(state.guestEmailId)
      };

    case t.VALIDATE_VST_ACTIVATION_CODE:
      return validateBothTypeOfCodes(state, null);
    case t.VALIDATE_ACTIVATION_CODE:
      return {
        ...state,
        redeemCodeValueIsValid: REDEEM_CODE_REGEX.test(state.redeemCodeValue),
        redeemCodeValueIsError: !REDEEM_CODE_REGEX.test(state.redeemCodeValue),
        isVstCode: false
      };
    case t.RESET_ERROR_MESSAGE_CODE:
      return {
        ...state,
        errorOccurred: false,
        errorMessage: null
      };
    // Reset the code
    case t.RESET_ACTIVATION_CODE:
      return {
        ...initialState
      };
    // Redeem code
    case t.REDEEM_CODE_REQUEST:
      return {
        ...state,
        formStateGuest: formStates.INPUTTING
      };
    case t.REDEEM_CODE_SUCCESS:
      return {
        ...state,
        formStateGuest: formStatesRedeemCodeGuest.SUCCESSFUL,
        errorOccurred: false,
        errorMessage: null
      };
    case t.REDEEM_CODE_FAILURE:
      return {
        ...state,
        formStateGuest: formStatesRedeemCodeGuest.FAILED,
        errorOccurred: true,
        errorMessage: action.data.errors
      };
    default:
      return state;
  }
}
