import * as t from '../../actionTypes';
// Constants
import { HubLayoutConstants } from '../../../globals/hubConstants';

const initialState = {
  sideNavOpen: false,
  redeemModalOpen: false,
  enterCodeModalOpen: false,
  createClassModalOpen: false,
  archiveClassPopupOpen: false,
  editAccountModalOpen: false,
  selfSelectRoleModalOpen: false,
  selfRegistereUserModalOpen: false,
  isTeacherInClass: true,
  myDashboardView: HubLayoutConstants.DASHBOARD_DEFAULT_VIEW,
  myTasksView: HubLayoutConstants.DASHBOARD_TASKS_DEFAULT_VIEW,
  addTeachersModalOpen: false,
  addStudentsModalOpen: false,
  setTestModalOpen: false,
  editClassroomModalOpen: false,
  editOrgModalOpen: false,
  materialsToClassModalOpen: false,
  orgModalOpen: false,
  classModalOpen: false,
  materialsToClassModalContent: '',
  recallLicenceModalOpen: false,
  statusStudentsAssessmenLicenceTab: false,
  assessmentTestId: '',
  onboardingWizardOpen: false,
  structuredContentPlayerModalOpen: false,
  deploymentToolModal: false,
  missingLicenseModalOpen: false,
  placementTestOnboardingWizardOpen: false,
  placementTestPanelOpen: false,
  placementTestSessionInformationModalOpen: false,
  selfRegisteredWizardOpen: false
};

function hubUi(
  state = initialState,
  {
    isTeacherInClass,
    type,
    myDashboardView,
    showSelfSelectedModal,
    showSelfRegisteredUserModal,
    materialsToClassModalContent,
    assessmentTestId
  }
) {
  switch (type) {
    case t.HUB_OPEN_SIDENAV:
      return {
        ...state,
        sideNavOpen: true
      };
    case t.HUB_SHOW_SELF_SELECT_ROLE_MODAL:
      return {
        ...state,
        selfSelectRoleModalOpen: showSelfSelectedModal
      };
    case t.HUB_SHOW_SELF_REGISTERED_USER_MODAL:
      return {
        ...state,
        selfRegistereUserModalOpen: showSelfRegisteredUserModal
      };
    case t.HUB_CLOSE_SIDENAV:
      return {
        ...state,
        sideNavOpen: false
      };
    case t.HUB_OPEN_REDEEM_MODAL:
      return {
        ...state,
        redeemModalOpen: true
      };
    case t.HUB_CLOSE_REDEEM_MODAL:
      return {
        ...state,
        redeemModalOpen: false
      };
    case t.HUB_OPEN_ENTER_CODE_MODAL:
      return {
        ...state,
        enterCodeModalOpen: true
      };
    case t.HUB_CLOSE_ENTER_CODE_MODAL:
      return {
        ...state,
        enterCodeModalOpen: false
      };
    case t.CPT_OPEN_STRUCTURED_CONTENT_PLAYER_MODAL:
      return {
        ...state,
        structuredContentPlayerModalOpen: true
      };
    case t.CPT_CLOSE_STRUCTURED_CONTENT_PLAYER_MODAL:
      return {
        ...state,
        structuredContentPlayerModalOpen: false
      };
    case t.HUB_OPEN_JOIN_CLASS_WITH_CODE_MODAL:
      return {
        ...state,
        joinClassWithCodeModalOpen: true
      };
    case t.HUB_CLOSE_JOIN_CLASS_WITH_CODE_MODAL:
      return {
        ...state,
        joinClassWithCodeModalOpen: false
      };
    case t.HUB_OPEN_CREATE_CLASS_MODAL:
      return {
        ...state,
        createClassModalOpen: true,
        isTeacherInClass
      };
    case t.HUB_CLOSE_CREATE_CLASS_MODAL:
      return {
        ...state,
        createClassModalOpen: false
      };
    case t.HUB_TOGGLE_ARCHIVE_CLASS_MODAL:
      return {
        ...state,
        archiveClassPopupOpen: !state.archiveClassPopupOpen
      };
    case t.HUB_OPEN_EDIT_ACCOUNT_MODAL:
      return {
        ...state,
        editAccountModalOpen: true
      };
    case t.HUB_CLOSE_EDIT_ACCOUNT_MODAL:
      return {
        ...state,
        editAccountModalOpen: false
      };
    case t.HUB_SELECT_MY_DASHBOARD_VIEW:
      return {
        ...state,
        myDashboardView
      };
    case t.HUB_OPEN_ADD_TEACHERS_MODAL:
      return {
        ...state,
        addTeachersModalOpen: true
      };
    case t.HUB_CLOSE_ADD_TEACHERS_MODAL:
      return {
        ...state,
        addTeachersModalOpen: false
      };
    case t.HUB_TOGGLE_ADD_STUDENTS_MODAL:
      return {
        ...state,
        addStudentsModalOpen: !state.addStudentsModalOpen
      };
    case t.HUB_TOGGLE_SET_TEST_MODAL:
      return {
        ...state,
        setTestModalOpen: !state.setTestModalOpen
      };
    case t.HUB_TOGGLE_STATUS_STUDENTS_ASSESSMENT_LICENCE_TAB:
      return {
        ...state,
        statusStudentsAssessmenLicenceTab: !state.statusStudentsAssessmenLicenceTab,
        assessmentTestId
      };
    case t.HUB_OPEN_EDIT_CLASSROOM_MODAL:
      return {
        ...state,
        editClassroomModalOpen: true
      };
    case t.HUB_CLOSE_EDIT_CLASSROOM_MODAL:
      return {
        ...state,
        editClassroomModalOpen: false
      };
    case t.HUB_CLOSE_MATERIALS_TO_CLASS_MODAL:
      return {
        ...state,
        materialsToClassModalOpen: false,
        classModalOpen: false,
        materialsToClassModalContent
      };
    case t.HUB_TOGGLE_MATERIALS_TO_CLASS_MODAL:
      return {
        ...state,
        materialsToClassModalOpen: !state.materialsToClassModalOpen,
        materialsToClassModalContent
      };
    case t.HUB_SET_MATERIALS_CONTEXT:
      return {
        ...state,
        materialsToClassModalContent
      };
    case t.HUB_OPEN_ADD_LICENSES_MODAL:
      return {
        ...state,
        addLicensesModalOpen: true
      };
    case t.HUB_CLOSE_ADD_LICENSES_MODAL:
      return {
        ...state,
        addLicensesModalOpen: false
      };
    case t.HUB_OPEN_EDIT_ORG_MODAL:
      return {
        ...state,
        editOrgModalOpen: true
      };
    case t.HUB_CLOSE_EDIT_ORG_MODAL:
      return {
        ...state,
        editOrgModalOpen: false
      };
    case t.HUB_TOGGLE_ORG_MODAL:
      return {
        ...state,
        orgModalOpen: !state.orgModalOpen
      };
    case t.HUB_TOGGLE_CLASS_MODAL:
      return {
        ...state,
        classModalOpen: !state.classModalOpen
      };
    case t.HUB_OPEN_RECALL_LICENCE_MODAL:
      return {
        ...state,
        recallLicenceModalOpen: true
      };
    case t.HUB_CLOSE_RECALL_LICENCE_MODAL:
      return {
        ...state,
        recallLicenceModalOpen: false
      };
    case t.HUB_OPEN_ONBOARDING_WIZARD:
      return {
        ...state,
        onboardingWizardOpen: true
      };
    case t.HUB_CLOSE_ONBOARDING_WIZARD:
      return {
        ...state,
        onboardingWizardOpen: false
      };
    case t.HUB_OPEN_PLACEMENT_TEST_ONBOARDING_WIZARD:
      return {
        ...state,
        placementTestOnboardingWizardOpen: true
      };
    case t.HUB_CLOSE_PLACEMENT_TEST_ONBOARDING_WIZARD:
      return {
        ...state,
        placementTestOnboardingWizardOpen: false
      };
    case t.HUB_OPEN_PLACEMENT_TEST_PANEL:
      return {
        ...state,
        placementTestPanelOpen: true
      };
    case t.HUB_CLOSE_PLACEMENT_TEST_PANEL:
      return {
        ...state,
        placementTestPanelOpen: false
      };
    case t.HUB_OPEN_PLACEMENT_TEST_SESSION_INFORMATION_MODAL:
      return {
        ...state,
        placementTestSessionInformationModalOpen: true
      };
    case t.HUB_CLOSE_PLACEMENT_TEST_SESSION_INFORMATION_MODAL:
      return {
        ...state,
        placementTestSessionInformationModalOpen: false
      };
    case t.HUB_TOGGLE_DEPLOYMENT_MODAL:
      return {
        ...state,
        deploymentToolModal: !state.deploymentToolModal
      };
    case t.HUB_OPEN_MISSING_LICENSE_MODAL:
      return {
        ...state,
        missingLicenseModalOpen: true
      };
    case t.HUB_CLOSE_MISSING_LICENSE_MODAL:
      return {
        ...state,
        missingLicenseModalOpen: false
      };
    case t.HUB_OPEN_SELF_REGISTERED_WIZARD:
      return {
        ...state,
        selfRegisteredWizardOpen: true
      };
    case t.HUB_CLOSE_SELF_REGISTERED_WIZARD:
      return {
        ...state,
        selfRegisteredWizardOpen: false
      };
    default:
      return state;
  }
}

export default hubUi;
