import * as t from '../actionTypes';

export const initialState = {
  items: []
};

export default function recentlyAccessed(state = initialState, { type, payload }) {
  switch (type) {
    case t.GET_RECENTLY_ACCESSED_SUCCESS:
      return {
        ...state,
        items: payload.history
      };
    default:
      return state;
  }
}
