import * as t from '../../actionTypes.js';
import { PLATFORMS } from '../../../../lambda/common/constants.js';

const initialState = {
  loading: false,
  products: [],
  error: null
};

export default function getLinkedProducts(state = initialState, action) {
  switch (action.type) {
    case t.NGI_FETCH_LINKED_PRODUCTS_REQUEST:
      return { ...state, loading: true, error: null };
    case t.NGI_FETCH_LINKED_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        products: action.payload.map(product => {
          if (product.platform === PLATFORMS.VST) {
            const unitsWithLessons = product.unitsWithLessons;
            if (product.userType === 'TEACHER') {
              unitsWithLessons.forEach(link => {
                link.uniqueIdentifier = `${product.isbnLaunch}${link.activityId || ''}`;
              });
            } else {
              unitsWithLessons.forEach(link => {
                link.uniqueIdentifier = `${product.isbnLaunch}${link.lessonId}`;
              });
            }
            if (product.unitsWithLessons[0]?.isClassRoom) {
              product.unitsWithLessons[0].numberOfActivities = 1;
            }
          }
          return product;
        })
      };
    case t.NGI_FETCH_LINKED_PRODUCTS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}
